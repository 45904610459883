.editor-collections-cards-container {
  padding: 20px;

  .card.search-card {
    border: 1px solid var(--search-page-filters-form-control);

    .card-body {
      flex-direction: column;
      padding: 0;

      img {
        border-radius: 8px;
        height: 100% !important;
        margin: 0;
        width: 100% !important;
      }

      &:hover img {
        opacity: 0.8;
      }

      .badge {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }

  .card.search-card + .card.search-card {
    margin-top: 10px;
  }
}

.app-page.collections-page .app-page-main-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
