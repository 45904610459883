@use "../edit-page.scss";

.DayPickerInput-Overlay {
  border: solid 1px var(--search-page-filters-border);
  box-shadow: 2px 4px 8px var(--search-page-filters-border-shadow);
}

.DayPicker-Caption {
  caption-side: top;
}

.DayPicker-Day {
  padding: 0.125rem 0.25rem;
}
