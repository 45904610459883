@use "../view-page.scss";

.drawer-content {
  .document-page-main-content .act-text {
    margin-right: 0;
  }

  textarea {
    margin-right: 10px;
    text-align: justify;
    white-space: normal;
  }
}
