.view-page {
  %metadata-card {
    background: var(--summary-view-page-card-background);
    border: 1px solid var(--summary-view-page-card-border);
    border-radius: 8px;
    height: 100%;

    div,
    button.btn.btn-link.inline-link {
      font-size: 0.9rem;
    }

    ul {
      list-style-type: none;
      margin-bottom: 0;
      padding-left: 0;
    }

    ul.compact-list {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      li + li > div::before {
        content: ", ";
      }

      div {
        white-space: nowrap;
      }
    }

    span {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
    }

    .card-subtitle {
      font-size: 1rem;
    }
  }

  .act-text {
    // margin-right: 15px;

    .r6o-annotation {
      background-color: rgb(255 165 0 / 20%);
      border-bottom: none;
      cursor: pointer;
    }

    div.related-acts {
      align-items: center;
      display: flex;
    }

    &.highlight-mode .r6o-annotation.categorized {
      background-color: rgb(157 181 255 / 10%);
      border-bottom: 1px dashed gray;
      cursor: pointer;
    }

    &.hide-user-anno .r6o-annotation:not(.categorized),
    &.hide-user-anno .r6o-selection:not(.categorized) {
      background-color: transparent;
      cursor: auto;
    }

    &.hide-editor-anno .r6o-annotation.categorized,
    &.hide-editor-anno .r6o-selection.categorized {
      background-color: transparent;
      border-bottom: none;
      cursor: auto;
    }

    button.btn.btn-link.inline-link,
    button.btn.btn-link.inline-link:focus,
    a.btn-link {
      color: var(--view-page-btn-link);
      text-align: justify;
      vertical-align: baseline;
    }

    button.btn.btn-link.inline-link {
      line-height: 1rem;
    }

    p {
      text-align: justify;
      text-indent: 40px;

      button {
        font-size: 1rem !important;
      }

      a {
        font-size: 1rem;
        padding-left: 0;
      }
    }

    blockquote {
      border-left: 5px solid var(--doc-page-blockquote);
      font-style: italic;
      margin-left: 1em;
      margin-right: 0;
      overflow: hidden;
      padding-left: 1.5em;
      padding-right: 1.5em;
      text-align: justify;
    }
  }

  .question-info-icon {
    bottom: 3px;
    color: var(--components-select-filter-dropdownIndicator);
    position: absolute;
    right: 40px;
    transform: translate(50%, -50%);
    z-index: 100;
  }

  .info-cards-row > div:not(:first-child) {
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .card.info-card {
    @extend %metadata-card;

    button.btn.btn-link.inline-link,
    button.btn.btn-link.inline-link:focus {
      text-align: justify;
      vertical-align: baseline;
    }
  }

  .card.highlight-card {
    @extend %metadata-card;

    background: var(--summary-view-page-card-question-background);
    border: 1px solid var(--summary-view-page-card-question-border);
    margin-top: 20px;

    .card-header {
      background: var(--background-color);
    }

    p.card-text {
      cursor: pointer;
      font-size: 1rem;
      text-align: justify;
    }

    div.highlight-card-details {
      font-size: 0.9rem;
      line-height: 1.2;
      padding-left: 20px;

      span.highlight-card-details-title {
        font-weight: 1rem;
      }
    }

    .badge.bg-dark {
      background-color: rgb(0 81 128) !important;
      margin-bottom: 0;
    }

    .annotated {
      line-height: 20px;
      margin-top: 5px;

      button {
        height: 20px;

        svg {
          vertical-align: baseline;
        }
      }
    }
  }

  .inline-link,
  .btn-link,
  .drawer-content .btn-link {
    color: var(--view-page-btn-link);
  }

  .dropdown-item {
    align-items: center;
    display: flex;
    gap: 8px;
    line-height: 1.2rem;
    text-decoration: none;

    svg {
      margin-top: 1px;
    }
  }
}
