@use "../DocumentPage/generic-popover.scss";

%submit-feedback-popover-conent {
  .current-filters-values {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .input-group {
    button,
    button:focus,
    button:active {
      background-color: var(--components-select-filter-control-background) !important;
      border-color: var(--components-select-filter-control-border) !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
}

#submit-feedback-popover {
  @extend %submit-feedback-popover-conent;

  button {
    margin-right: 0 !important;
  }
}

#submit-feedback-modal {
  @extend %submit-feedback-popover-conent;
  @extend %popover-contents;

  height: auto;
}
