.referenced-from-drawer {
  padding-left: 10px;
  padding-right: 10px;

  ul {
    list-style-type: decimal;

    button.btn.btn-link.inline-link {
      vertical-align: baseline;
    }
  }
}
