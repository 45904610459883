nav.toc {
  align-self: flex-start;
  counter-reset: h2counter;
  overflow: auto;

  ul li {
    margin-bottom: 5px;
  }

  .head2 {
    counter-reset: h3counter;
  }

  .head3 {
    counter-reset: h4counter;
    list-style-type: none;
    margin-left: 10px;
  }

  .head4 {
    counter-reset: h5counter;
    list-style-type: none;
    margin-left: 20px;
  }

  .head5 {
    list-style-type: none;
    margin-left: 30px;
  }

  .head2::before {
    color: var(--view-page-btn-link);
    content: counter(h2counter) ". ";
    counter-increment: h2counter;
  }

  .head3::before {
    color: var(--view-page-btn-link);
    content: counter(h2counter) "." counter(h3counter) ". ";
    counter-increment: h3counter;
  }

  .head4::before {
    color: var(--view-page-btn-link);
    content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ". ";
    counter-increment: h4counter;
  }

  .head5::before {
    color: var(--view-page-btn-link);
    content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "." counter(h5counter)
      ". ";
    counter-increment: h5counter;
  }
}
