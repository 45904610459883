.home-container {
  background-color: var(--background-color);
  padding-bottom: 20px;
  padding-left: 11%;
  padding-right: 11%;
  width: 100%;
}

.home-page {
  padding-top: 40px;
}

.mobile-view .home-page {
  padding-bottom: 40px;
  padding-top: 0;
}

@media (max-width: 1170px) {
  .home-container {
    padding-left: 3%;
    padding-right: 3%;
  }
}

.focused-section-container {
  padding: 10px 0;
}

.focused-section-container > div {
  display: table-row;
  padding: 6px;
}

div.timeline-header {
  align-items: center;
  border-bottom: 2px solid rgb(235 238 247);
  display: flex;
  margin: 10px auto;
}

div.timeline-header > span.timeline-header-title {
  font-size: 1.2rem;
  font-weight: 300;
  white-space: nowrap;
}

div.timeline-header > div.timeline-header-content {
  margin-left: auto;
  order: 2;
  width: 300px;
}

div.timeline-header .dropdown-container {
  display: flex;
}

div.timeline-header .dropdown-heading {
  margin-left: auto;
  width: fit-content;
}
