.edit-form {
  .card.highlight-card {
    background: var(--summary-view-page-card-question-background);
    border: 1px solid var(--summary-view-page-card-question-border);
    border-radius: 8px;
    margin-top: 10px;

    .card-subtitle {
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      text-indent: 0;
    }
  }

  & .row:not(:first-of-type) {
    margin-top: 10px;
  }

  .form-control {
    background: var(--doc-page-edit-form-background);
    background-color: var(--components-select-filter-control-background);
    border: 1px solid var(--doc-page-edit-form);
    border-color: var(--components-select-filter-control-border);
    border-radius: 2px;
    box-shadow: none;
    color: var(--doc-page-edit-form);
    color: inherit;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    height: 32px;
    line-height: 1;
    padding: 0.5em 0.75em;
    text-decoration: none;

    &:disabled,
    &[readonly] {
      background-color: var(--doc-page-edit-form-background-readonly);
      opacity: 1;
    }
  }

  .input-group-text {
    border: 1px solid var(--doc-page-edit-form);
    border-color: var(--components-select-filter-control-border);
    border-radius: 2px;
    height: 32px;
  }

  .input-group button,
  .input-group a {
    background-color: var(--components-button-secondary);
    border-color: var(--components-button-secondary);
    padding-bottom: 0;
    padding-top: 0;

    &:active,
    &:focus {
      background-color: var(--components-button-secondary) !important;
      border-color: var(--components-button-secondary) !important;
      outline: none !important;
    }

    svg {
      height: 12px;
      vertical-align: unset;
      width: 12px;
    }
  }

  .form-label {
    color: var(--doc-page-edit-form-label);
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }

  .form-group {
    margin-bottom: 0.6rem;
  }

  .rmsc .dropdown-heading-value {
    color: var(--rmsc-gray);
    font-size: 1rem;
    font-style: italic;
  }

  /* summary page */

  .rbt-input-hint {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    height: 32px;
    line-height: 1;
    padding: 0.5em 0.75em;
    text-decoration: none;
  }

  #provision-text {
    height: 250px;
  }

  label[for="provisions"],
  label[for*="topics"],
  label[for*="dissentingOpinions"],
  label[for*="editorialOpinions"] {
    display: flex;

    button.btn {
      margin-left: auto;
    }
  }

  .sub-section-table button.remove-link {
    display: block;
    margin: auto;
    padding: 0;
    visibility: hidden;
  }

  .sub-section-table tr:hover button.remove-link {
    visibility: visible;
  }

  .sub-section-table {
    .lv-label {
      font-size: 0.9rem;
      font-weight: 600;
    }

    button.btn.btn-link.inline-link {
      font-size: 0.9rem !important;
    }
  }

  ul.sub-section-tabs {
    flex-wrap: nowrap;
    margin-top: 20px;

    &.editable {
      li {
        display: flex;
        flex-shrink: 1;
        min-width: 0;

        button {
          align-items: center;
          display: flex;
          min-width: 0;

          span {
            display: block;
            flex-shrink: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &:first-of-type {
          flex-shrink: 0;
        }

        &:last-of-type {
          flex-shrink: 0;
          margin-left: auto;
        }

        & + .tab-content {
          padding: 12px;
        }
      }
    }
  }

  .styleless-readonly {
    background-color: var(--components-select-filter-control-background) !important;
  }
}
