.checkbox input[type="checkbox"] {
  display: none;

  & + label::before {
    color: var(--search-page-results-table-actions);
    content: "\f0c8";
    cursor: pointer;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "Font Awesome 5 Free";
    font-size: 1.2rem;
    font-weight: 100;
  }

  &:checked + label::before {
    content: "\f14a";
  }
}
