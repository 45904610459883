.rmsc .panel-content {
  color: var(--components-select-filter-option-disabled);
  font-size: 16px;
}

.rmsc .panel-content label {
  font-weight: 400;
  line-height: 24px;
  padding: 0;
}

.rmsc .panel-content .options {
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
}

.rmsc .panel-content label:focus:not(.selected),
.rmsc .panel-content label:hover {
  background-color: inherit;
  color: inherit;
}

.rmsc .panel-content label .item-renderer {
  padding: 5px 12px;
}

.rmsc .panel-content label .item-renderer:hover {
  background-color: var(--components-select-filter-option-background-enabled);
  color: var(--components-select-filter-option-focused);
}

.rmsc .panel-content label .item-renderer.disabled {
  background-color: inherit;
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: line-through;
}

.rmsc .panel-content label .item-renderer.disabled.no-options {
  align-items: center;
  color: var(--rmsc-gray);
  text-decoration: initial;
}

.rmsc .panel-content label .item-renderer.disabled.group-label {
  color: rgb(153 153 153);
  cursor: default;
  display: block;
  font-size: 90%;
  font-weight: 500;
  margin-bottom: 0.25em;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
  text-transform: uppercase;
}

.rmsc.disabled .dropdown-container {
  background-color: var(--doc-page-edit-form-background-readonly) !important;
}

.rmsc .panel-content label.selected {
  color: inherit;
}

.dropdown-content {
  padding-top: 0.4rem;
}

.dropdown-content .item-renderer {
  flex-direction: column;
}

.dropdown-content .title {
  font-weight: 500;
}

.dropdown-content .sub-title {
  font-size: 0.7rem;
  line-height: 0.8rem;
}

.dropdown-heading:focus,
.dropdown-heading:active,
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  outline: none !important;
}

.rmsc .dropdown-container:hover {
  border-color: rgb(206 212 218) !important;
}

.rmsc.disabled .dropdown-container:hover {
  border-color: var(--components-select-filter-control-border) !important;
  cursor: default !important;
}

.rmsc .dropdown-container {
  background-color: var(--components-select-filter-control-background) !important;
  border-color: var(--components-select-filter-control-border) !important;
}

.rmsc .dropdown-heading {
  height: fit-content !important;
  min-height: 30px;
  padding-right: 0 !important;
}

.rmsc .dropdown-heading:hover {
  cursor: pointer !important;
}

.rmsc.disabled .dropdown-heading:hover {
  cursor: default !important;
}

.rmsc.disabled,
.rmsc.disabled * {
  cursor: default !important;
}

.rmsc .dropdown-heading-value {
  color: var(--rmsc-gray);
  font-style: italic;
}

.rmsc .dropdown-heading .arrow {
  align-items: center;
  align-self: stretch;
  color: var(--components-select-filter-dropdownIndicator);
  display: flex;
  padding-right: 10px;
}

.rmsc .dropdown-heading .arrow:hover {
  color: var(--components-select-filter-dropdownIndicator-hover);
}

.rmsc.disabled .dropdown-heading .arrow:hover {
  color: var(--components-select-filter-dropdownIndicator);
}

.rmsc .clear-selected-button {
  color: rgb(204 204 204);
}

.rmsc .clear-selected-button svg {
  margin-right: 10px;
}

.rmsc .clear-selected-button:hover {
  color: rgb(153 153 153);
}

.rmsc.disabled .clear-selected-button:hover {
  color: rgb(204 204 204);
}

.rmsc .multi-value {
  align-items: center;
  background: rgb(255 255 255);
  border-radius: 2px;
  box-sizing: border-box;
  color: rgb(51 51 51);
  display: flex;
  margin: 0 2px;
  min-width: 0;
}

.rmsc .multi-value-label {
  border-radius: 2px;
  box-sizing: border-box;
  font-style: normal;
  overflow: hidden;
  padding: 0 0 0 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rmsc .multi-value-remove {
  align-items: center;
  border-radius: 2px;
  -webkit-box-align: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding-left: 4px;
  padding-right: 4px;
}

.rmsc .multi-value-remove:hover {
  background-color: rgb(255 189 173);
  color: rgb(222 53 11);
}

.rmsc .multi-value svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.rmsc .dropdown-heading-value > span {
  color: var(--search-page-filters-form-control-placeholder);
  display: flex;
  flex-wrap: wrap;
}

.input-group .rmsc {
  flex-grow: 1;
}

.rmsc * {
  transition: none !important;
}

.selection-drawer {
  height: 50vh;
  z-index: 1060;
}

.selection-drawer .offcanvas-body {
  padding: 0;

  .rmsc.mobile-only .options li:last-of-type {
    margin-bottom: 40px;
  }
}

.selection-drawer .rmsc .dropdown-container {
  display: flex;
  flex-direction: column;
}

.selection-drawer .dropdown-container,
.selection-drawer .dropdown-content,
.selection-drawer .panel-content,
.selection-drawer .select-panel,
.selection-drawer .options {
  border: none !important;
  height: 100%;
  max-height: none !important;
}

.selection-drawer .rmsc {
  height: 100%;
}

.selection-drawer .dropdown-content {
  padding-top: 0 !important;
  position: static !important;
}

.selection-drawer .rmsc .dropdown-heading {
  display: none;
}

.responsive-select .rmsc.mobile-only .panel-content {
  display: none !important;
}

.selection-drawer .rmsc.mobile-only .panel-content {
  border-radius: 0 !important;
  display: block !important;
}

.rmsc.inline-select {
  .dropdown-container {
    background-color: transparent !important;
    border: none;

    .dropdown-heading {
      padding: 0;

      .dropdown-heading-value span {
        color: var(--view-page-btn-link);
        font-weight: 500;
      }

      .arrow {
        color: var(--view-page-btn-link);
        padding-right: 0;
      }
    }

    .dropdown-content {
      overflow: visible;
      padding-top: 0;
      width: auto;
    }
  }

  .clear-selected-button {
    display: none !important;
  }
}
