@use "../edit-page.scss";

.summary-page.edit-page {
  .table > :not(:first-child) {
    border-top: none;
  }

  .nav-item.nav-link.add-link {
    margin-left: auto;
  }

  div.table-responsive {
    margin-left: 0;
    margin-right: 0;
    max-height: 300px;
    overflow: auto;
  }

  .rbt-input-hint {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    height: 32px;
    line-height: 1;
    padding: 0.5em 0.75em;
    text-decoration: none;
  }

  label[for="provisions"],
  label[for*="topics"],
  label[for*="dissentingOpinions"],
  label[for*="editorialOpinions"] {
    display: flex;
  }

  label[for="provisions"] button,
  label[for*="topics"] button,
  label[for*="dissentingOpinions"] button,
  label[for*="editorialOpinions"] button {
    margin-left: auto;
  }

  .question-tabs {
    margin-top: 30px;

    nav .nav-item.nav-link {
      flex: none;

      &.disabled {
        text-align: left;
      }
    }

    .question-tab {
      min-width: 100px;
    }
  }

  .btn-link {
    color: var(--view-page-btn-link);
  }

  ul.question-sub-tabs {
    margin-top: 20px;

    li:last-of-type {
      margin-left: auto;
    }

    li:first-of-type button {
      color: var(--doc-page-edit-form-label);
      font-weight: 500;
    }
  }

  #editor-comments .ql-editor {
    max-height: 300px;
    min-height: 100px;
  }
}

#provision-text {
  height: 250px;
}

#annotation-tooltip {
  z-index: var(--z-tooltip);
}
