.related-acts button.remove-link {
  display: block;
  margin: auto;
  padding: 0;
  visibility: hidden;
}

.related-acts tr:hover button.remove-link {
  visibility: visible;
}

.related-acts.table > :not(:first-child) {
  border-top: none;
}

ul.question-sub-tabs {
  flex-wrap: nowrap;
  margin-top: 20px;
}

ul.question-sub-tabs li {
  display: flex;
  flex-shrink: 1;
  min-width: 0;
}

ul.question-sub-tabs li button {
  align-items: center;
  display: flex;
  min-width: 0;
}

ul.question-sub-tabs li button span{
  display: block;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.question-sub-tabs li:first-of-type {
  flex-shrink: 0;
}


ul.question-sub-tabs li:last-of-type {
  flex-shrink: 0;
  margin-left: auto;
}

ul.question-sub-tabs + .tab-content {
  padding: 12px;
}
