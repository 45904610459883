/* stylelint-disable custom-property-pattern */
:root {
  /* z-axis positioning */
  --z-index-drawer-handle: 50;/* x */
  --z-index-scroll-top-arrow: 100;/* x */
  --z-tooltip: 150;/* x */
  --z-tooltip-actions-popover: 175;/* x */
  --z-index-top-nav-bar: 200;
  --z-index-left-nav-panel-overlay: 299;/* x */
  --z-index-left-nav-panel: 300;/* x */
  --z-index-search-filters-mobile-overlay: 349;/* x */
  --z-index-search-filters-mobile: 350;/* x */
  --z-index-right-document-panel-overlay: 399;/* x */
  --z-index-right-document-panel: 400;
  --z-tooltip-actions-popover-quickview: 401;/* x */
  --z-index-popover: 500;/* x */
  --z-index-modals: 600;
  --scroll-bar-color: rgb(197 197 197);
  --scroll-bar-bg-color: rgb(246 246 246);

  /* formatting */
  --background-color: white;
  --view-page-btn-link: rgb(0 81 128);
  --badge-primary: rgb(0 81 128);
  --badge-warning: rgb(242 191 58);
  --badge-success: rgb(0 125 87);
  --badge-danger: rgb(153 35 64);
  --badge-info: rgb(0 129 151);
  --app-user-bar-background: white;
  --app-logo: rgb(154 174 187);
  --app-site-layout-background: white;
  --app-navbar-background: rgb(249 249 249);
  --app-navbar-border: rgb(224 241 240);
  --app-navbar-link-hover: rgb(0 81 128);
  --app-navbar-link-active: rgb(0 81 128);
  --app-navbar-light-link: rgb(0 81 128);
  --app-help-section-btn: rgb(0 81 128);
  --app-help-section-btn-border: rgb(224 241 240);
  --app-help-section-btn-background: white;
  --app-help-section-btn-hover-border: rgb(249 249 249);
  --app-help-section-btn-hover-background: white;
  --app-div-root-background: rgb(249 249 249);
  --app-avatar: rgb(0 81 128);
  --app-avatar-border: rgb(0 81 128);
  --app-toast-info: rgb(0 81 128);
  --app-toast-error: rgb(153 35 64);
  --app-toast-success: rgb(126 217 124);
  --app-toast-warning: rgb(242 191 58);
  --app-rst-moveHandle-background: rgb(0 81 128);
  --history-page-active-filter: rgb(0 81 128);
  --history-page-filters-form-control-background: rgb(249 249 249);
  --history-page-filters-form-control-border: rgb(224 241 240);
  --home-page-background: white;
  --home-page-carousel: rgb(0 81 128);
  --home-page-article-details: white;
  --home-page-article-details-background: rgb(153 35 64);
  --home-page-article-details-box-shadow: none;
  --home-page-focused-section: white;
  --home-page-focused-section-background: rgb(0 81 128);
  --home-page-focused-section-container: black;
  --home-page-timeline-line-background: rgb(66 66 66);
  --home-page-timeline-background: var(--summary-view-page-card-question-background);
  --home-page-timeline-background-border: lightgray;
  --home-page-timeline-background-box-shadow: none;
  --home-page-timeline-vertical-icon: white;
  --home-page-timeline-element-icon: white;
  --home-page-timeline-element-icon-background: rgb(153 35 64);
  --search-page-results-background: white;
  --search-page-results-border: rgb(224 241 240);
  --search-page-results-card-header: rgb(0 36 80);
  --search-page-results-shadow: none;
  --search-page-results-btn: rgb(0 81 128);
  --search-page-results-btn-background: white;
  --search-page-results-btn-border: white;
  --search-page-results-btn-shadow: none;
  --search-page-results-current-filters-border: rgb(224 241 240);
  --search-page-results-current-filters-chip-border: rgb(224 241 240);
  --search-page-results-current-filters-chip-background: rgb(249 249 249);
  --search-page-results-current-filters-btn-clear-search-hover: rgb(0 74 123);
  --search-page-results-current-filters-btn-clear-search: rgb(179 8 56);
  --search-page-results-search-pane: rgb(0 36 80);
  --search-page-results-search-pane-background: white;
  --search-page-results-search-pane-border: rgb(0 81 128);
  --search-page-results-search-pane-active-background: white;
  --search-page-results-table-background: white;
  --search-page-results-table: rgb(0 36 80);
  --search-page-results-table-title: rgb(0 81 128);
  --search-page-results-table-border: white;
  --search-page-results-table-href: 005180;
  --search-page-results-table-impact-factor: rgb(153 35 64);
  --search-page-results-table-btn-border: rgb(224 241 240);
  --search-page-results-table-btn: rgb(67 70 86);
  --search-page-results-table-btn-background: white;
  --search-page-results-table-btn-viewmode-background: rgb(0 74 123);
  --search-page-results-table-btn-viewmode: white;
  --search-page-results-table-btn-viewmode-border: rgb(224 241 240);
  --search-page-results-table-btn-viewmode-active-background: rgb(0 81 128);
  --search-page-results-table-btn-viewmode-active: white;
  --search-page-results-table-float-right-btn-hover: rgb(67 70 86);
  --search-page-landing-page-container-background: white;
  --search-page-landing-page-container-border: rgb(224 241 240);
  --search-page-landing-page-container-border-shadow: rgba(0 0 0 5%);
  --search-page-landing-page-card-background: rgb(249 249 249);
  --search-page-landing-page-card-background-hover: rgb(245 245 245);
  --search-page-landing-page-card: rgb(0 81 128);
  --search-page-landing-page-card-shadow: none;
  --search-page-landing-page-card-border: rgb(224 241 240);
  --search-page-landing-page-card-border-hover: lightgray;
  --search-page-filters: rgb(0 36 80);
  --search-page-filters-border: rgb(224 241 240);
  --search-page-filters-border-shadow: rgba(0 0 0 5%);
  --search-page-filters-background: white;
  --search-page-filters-border-left: rgb(0 81 128);
  --search-page-filters-border-top: rgb(224 241 240);
  --search-page-filters-border-bottom: rgb(224 241 240);
  --search-page-filters-border-right: rgb(224 241 240);
  --search-page-filters-form-control-background: rgb(249 249 249);
  --search-page-filters-form-control-border: rgb(224 241 240);
  --search-page-filters-form-control: rgb(122 126 129);
  --search-page-filters-form-control-shadow: none;
  --search-page-filters-form-control-placeholder: rgb(114 141 161);
  --search-page-filters-form-control-btn: darkgray;
  --search-page-filters-form-control-btn-border: rgb(249 249 249);
  --search-page-filters-form-control-btn-background: rgb(249 249 249);
  --search-page-filters-form-control-btn-shadow: none;
  --search-page-filters-btn-link: rgb(88 142 247);
  --doc-page-header: gray;
  --doc-page-header-border: lightgray;
  --doc-page-edit-form: rgb(224 241 240);
  --doc-page-edit-form-shadow: none;
  --doc-page-edit-form-background: white;
  --doc-page-edit-form-background-readonly: rgb(241 239 239);
  --doc-page-edit-form-label: rgb(0 36 80);
  --doc-page-edit-form-nav-link: rgb(0 81 128);
  --doc-page-edit-form-nav-link-border: white;
  --doc-page-edit-form-nav-link-border-bottom: rgb(222 226 230);
  --doc-page-blockquote: rgb(204 204 204);
  --doc-page-edit-missing-information: rgb(153 35 64);
  --summary-view-page-card-border: rgb(224 241 240);
  --summary-view-page-card-background: rgb(249 249 249);
  --summary-view-page-card-question-background: rgb(247 249 255);
  --summary-view-page-card-question-border: lightgray;
  --summary-view-page-card-linked-act-background: white;
  --summary-view-page-nav-link: rgb(0 81 128);
  --summary-view-page-nav-link-active: rgb(0 81 128);
  --components-select-menu-border: rgb(224 241 240);
  --components-select-menu-shadow: none;
  --components-select-shadow: none;
  --components-select-filter-dropdownIndicator: darkgray;
  --components-select-filter-dropdownIndicator-background: rgb(249 249 249);
  --components-select-filter-dropdownIndicator-hover: lightgray;
  --components-select-filter-indicatorSeparator-background: transparent;
  --components-select-filter-control-border: rgb(224 241 240);
  --components-select-filter-control-background: rgb(249 249 249);
  --components-select-filter-control-border-hover: lightgray;
  --components-select-filter-singleValue: rgb(154 174 187);
  --components-select-filter-multiValue-background: white;
  --components-select-filter-option-disabled: rgb(0 36 80);
  --components-select-filter-option-focused: white;
  --components-select-filter-option-background-enabled: rgb(0 81 128);
  --components-select-filter-option-background-selected: rgb(224 241 240);
  --components-select-filter-option-background-disabled: transparent;
  --components-simple-select-filter-option-color: black;
  --components-button-load-more: white;
  --components-button-load-more-background: rgb(0 81 128);
  --components-button-load-more-shadow: none;
  --components-button-load-more-hover: white;
  --components-button-load-more-hover-background: rgb(0 81 128);
  --components-button-important: white;
  --components-button-important-background: rgb(153 35 64);
  --components-button-important-shadow: none;
  --components-button-important-hover: white;
  --components-button-important-hover-background: rgb(153 35 64);
  --components-button-secondary: rgb(0 81 128);
  --components-button-secondary-background: rgb(248 249 250);
  --components-button-secondary-shadow: none;
  --components-button-secondary-hover: rgb(67 70 86);
  --components-button-secondary-hover-background: rgb(247 249 255);
  --components-sticky-shadow: none;
  --components-checkbox-tree: rgb(0 81 128);
  --components-checkbox-tree-input-background: rgb(0 81 128);
  --components-horizontal-scroll-menu-border: lightgray;
  --components-horizontal-scroll-menu-nav-right-paddle-border: rgb(224 241 240);
  --components-horizontal-scroll-menu-nav-right-paddle-background-gradient-start: hsla(
    0deg 0% 100% 2%
  );
  --components-horizontal-scroll-menu-nav-right-paddle-background-gradient-end: white;
  --components-horizontal-scroll-menu-nav-left-paddle-border: rgb(224 241 240);
  --components-horizontal-scroll-menu-nav-left-paddle-background-gradient-start: white;
  --components-horizontal-scroll-menu-nav-left-paddle-background-gradient-end: hsla(
    0deg 0% 100% 20%
  );
  --components-rich-text-editor-shadow: none;
  --components-rich-text-editor-background: rgb(249 249 249);
  --components-rich-text-editor-border: rgb(224 241 240);
  --components-rich-text-editor-white: white;
  --components-rich-text-editor-focus-border: rgb(154 174 187);
  --components-rich-text-editor-text: rgb(0 81 128);
  --components-rich-text-editor-shadow-drop: rgb(154 174 187);
  --components-rich-text-editor-shadow-inner: rgb(154 174 187);
  --components-rich-text-editor-button-default-hover-background: darkgray;
  --components-rich-text-editor-button-default-active-background: lightgray;
  --components-rich-text-editor-input-border: lightgray;
  --component-scroll-top-arrow: rgb(0 81 128);
  --font-family: "Lucida Grande", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif, -apple-system, system-ui;
}

body {
  background-color: var(--background-color) !important;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  scroll-behavior: auto !important;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;
  scroll-behavior: auto !important;
  width: 100%;
}

p,
.p {
  line-height: 20px;
}

.react-checkbox-tree label {
  margin-bottom: 0;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

/* div.modal-xl {
  max-width: 80%;
  max-height: 80%;
} */

div.modal-xxl .modal-dialog,
.modal-content {
  /* 80% of window height */
  height: 90%;
}

div.modal-xxl .modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: auto;
}

.badge.bg-primary {
  background-color: var(--badge-primary) !important;
}

.badge.bg-warning {
  background-color: var(--badge-warning) !important;
}

.badge.bg-success {
  background-color: var(--badge-success) !important;
}

.badge.bg-danger {
  background-color: var(--badge-danger) !important;
}

.badge.bg-info {
  background-color: var(--badge-info) !important;
}

.container-fluid .page-navigation {
  background-color: var(--search-page-landing-page-container-background);
  border: 1px solid var(--search-page-landing-page-container-border);
  box-shadow: 2px 4px 8px var(--search-page-landing-page-container-border-shadow);
  min-height: 315px;
  padding-top: 10px;
}

.carousel .control-dots .dot {
  background: var(--app-navbar-link-active);
  border-radius: 50%;
  box-shadow: 1px 1px 2px var(--search-page-landing-page-container-border-shadow);
  height: 8px;
  margin: 0 8px;
  width: 8px;
}

.nav-item.nav-link.disabled {
  border-color: var(--background-color) !important;
  border-bottom-color: var(--doc-page-edit-form-nav-link-border-bottom) !important;
  color: var(--app-navbar-link-hover);
  font-weight: 500;
  padding-left: 0;
}

.nav-item.nav-link {
  flex: 0 1 50%;
  overflow: hidden;
  padding: 6px 10px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-navigation .tab-pane {
  font-size: 0.9rem;
}

.app-page-navigation .rct-text,
.filters-sidebar .rct-text {
  display: flex;
}

.app-page-navigation .rct-text label,
.filters-sidebar .rct-text label {
  align-items: baseline;
  display: flex;
  width: 100%;
}

.app-page-navigation .rct-title,
.filters-sidebar .rct-title {
  overflow: hidden;
  text-overflow: ellipsis;

  /* max-height: 3.6em; */
  width: 90%;
}

.tooltip-inner {
  max-width: 250px;
  text-align: left;
}

.badge {
  padding: 0.25em 0.45em;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

h5 {
  font-size: 1.1rem;
}

.print-header,
.print-footer {
  display: none;
}

@media print {
  @page {
    margin-bottom: 0;
    margin-top: 0;
  }

  .user-bar,
  .menu-bar,
  .like-button,
  #page-map,
  .drawer-handle,
  .popover-body,
  .tooltip,
  .tooltip-inner,
  .document-status-badge,
  .document-page-main-content-header-actions,
  .scrollTop,
  svg,
  .filters-sidebar .card-header,
  .related-judgement-acts-summary {
    display: none !important;
  }

  #root {
    background-color: transparent !important;
  }

  .app-page-main-content {
    border: none !important;
  }

  .document-page-main-content-header-container {
    position: fixed;
    top: 20px;
    width: 100%;
  }

  .document-page-main-content-header {
    margin-bottom: 20px !important;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  thead tr td {
    height: 70px;
    padding-top: 20px;
  }

  .document-page {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  tr > td > div {
    padding: 0 !important;
  }

  .act-text {
    margin-right: 0 !important;
  }

  tfoot tr td {
    background-color: transparent;
    height: 70px;
  }

  .print-footer {
    background-color: transparent !important;
    border: none !important;
    border-top: 2px solid var(--doc-page-header-border) !important;
    bottom: 20px;
    display: flex;
    height: 40px;
    margin-top: 0;
    padding-top: 0;
    position: fixed;
    width: 100%;
  }

  .filters-sidebar .card {
    border: none !important;
  }

  .filters-sidebar .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .print-header {
    border-bottom: 2px solid rgb(0 0 0) !important;
    display: block;
    margin-bottom: 30px;
  }

  div[role="tablist"] {
    display: none;
  }

  button,
  a {
    color: rgb(0 0 0) !important;
  }
}

.headroom {
  transition: transform 100ms linear;
  will-change: transform;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.h-0 {
  left: -9999px;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}