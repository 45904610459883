.card.search-card {
  background-color: var(--search-page-landing-page-card-background);
  border: 1px solid var(--search-page-landing-page-card-border);
  border-radius: 8px;
  color: var(--search-page-landing-page-card);
  cursor: pointer;
  transition: box-shadow 0.3s;

  .card-remove-link {
    display: none;

    button.btn.btn-link.inline-link {
      font-size: 0.8rem;

      &:focus {
        font-size: 0.8rem;
      }
    }
  }

  &:hover {
    background-color: var(--search-page-landing-page-card-background-hover);
    border-color: var(--search-page-landing-page-card-border-hover);
    box-shadow: 0 0 11px var(--search-page-landing-page-card-shadow);

    .card-remove-link {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }

  .card-body {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-top: 15px;

    img {
      height: 50px !important;
      margin-right: 15px;
      opacity: 0.6;
      width: 50px !important;
    }

    div.card-title {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;

      .sub-title {
        font-size: 0.6rem;
        margin-top: 5px;
      }
    }

    p.card-text {
      font-size: 0.8rem;
      line-height: 16px;
    }

    .newly-added-link:hover {
      text-decoration: underline;
    }
  }

  &.subscribe-topic-card {
    border-style: dashed;
    min-height: 124px;
    opacity: 0.5;

    &:hover {
      border-style: solid;
      opacity: 1;
    }
  }
}
