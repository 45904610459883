div.focused-section > * {
  padding-left: 8px;
}

div.focused-section h2 {
  background: var(--home-page-focused-section-background);
  box-sizing: border-box;
  color: var(--home-page-focused-section);
  color: var(--home-page-focused-section);
  font-size: 1.1rem;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  margin-bottom: 0;
}

div.focused-section a {
  color: var(--home-page-focused-section-container);

  /* font-size: 0.9rem; */
}

div.focused-section .item-details {
  font-size: 0.7rem;
  margin-bottom: 10px;
  margin-top: 5px;
}

div.focused-section .label {
  font-weight: 600;
  margin-right: 5px;
}

div.focused-section .item-heading {
  font-size: 0.94rem;
  font-weight: 700;
  margin-top: 1px;
}

div.focused-section .missing-articles {
  font-size: 1rem;
  margin-top: 1px;
}

div.focused-section > div {
  font-size: 0.9rem;
  font-weight: 500;
}

div.focused-section .view-all-link {
  bottom: 0;
  font-size: 0.9rem;
  position: absolute;
  right: 10px;
}

div.focused-section {
  position: relative;

  /* height: 100%; */
}

div.focused-section .preview {
  -webkit-box-orient: vertical;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  overflow: hidden;
}
