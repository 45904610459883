.provisions-table button.remove-link {
  display: none;
  margin: 0;
  padding: 0;
}

.provisions-table tr:hover button.remove-link {
  display: contents;
}

.provisions-table {
  table-layout: fixed;
}

.provisions-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
