div#root {
  background-color: var(--app-div-root-background);
  height: 100%;
  margin: auto;
  min-height: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
}

.Toastify__toast--info {
  background-color: var(--app-toast-info);
  color: white;
}

.Toastify__toast--success {
  background-color: var(--app-toast-success);
  color: white;
}

.Toastify__toast--warning {
  background-color: var(--app-toast-warning);
  color: white;
}

.Toastify__toast--error {
  background-color: var(--app-toast-error);
  color: white;
}

.rst__moveHandle {
  background-color: var(--app-rst-moveHandle-background) !important;
}

/* helpers */
.display-block {
  display: block;
}

.fontweight-bold {
  font-weight: bold;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

input.form-control:focus,
.noSelect:focus,
.noSelect:active {
  border-color: rgb(206 212 218);
  box-shadow: none;
  outline: none !important;
}

.router {
  padding: 0;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem !important;
}

a,
.btn-link {
  text-decoration: none;
}

a:hover,
.btn-link:hover {
  text-decoration: underline;
}

a.nav-link:hover {
  text-decoration: none;
}

.subscription-dialog {
  margin-top: 30vh;
}

.form-check-input:checked {
  background-color: rgb(0 81 128);
  border-color: rgb(0 81 128);
}