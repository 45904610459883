@use "../DocumentPage/generic-popover.scss";

%highlight {
  .input-group {
    button,
    button:focus,
    button:active {
      background-color: var(--components-select-filter-control-background) !important;
      border-color: var(--components-select-filter-control-border) !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
}

#highlight-popover {
  @extend %highlight;

  min-width: 400px !important;
  position: fixed !important;
  width: 400px !important;

  &[data-popper-reference-hidden="true"] {
    margin-top: -8px;
  }

  .form-switch {
    float: right;
  }

  button {
    margin-right: 0 !important;
  }
}

#highlight-popover-quick {
  @extend %highlight;

  position: absolute !important;

  &[data-popper-reference-hidden="true"] {
    margin-top: -8px;
  }

  button {
    margin-right: 0 !important;
  }
}

#highlight-modal {
  @extend %highlight;

  height: auto;
  z-index: 1055;
}
