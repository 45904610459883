.document-page .app-page-navigation,
#navigation-drawer {
  button.btn.btn-link.inline-link {
    font-size: 0.9rem;
  }

  .btn-link,
  .inline-link {
    color: var(--view-page-btn-link);
  }

  .accordion {
    .card-body {
      padding: 1rem;
    }

    .card {
      border: solid 1px var(--search-page-filters-border);
      box-shadow: 2px 4px 8px var(--search-page-filters-border-shadow);
      margin-bottom: 5px;
      margin-top: 0;
      padding: 0;
    }

    & > .card > .card-header {
      background: var(--search-page-filters-background);
      border-left: 4px solid var(--search-page-filters-border-left);
      color: var(--search-page-filters);
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 500;
      margin-bottom: 0 !important;
      padding: 0.5rem 0;
    }

    .card-header {
      .container {
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
      }

      svg {
        align-self: center;
        font-size: 1rem;
        margin-right: 8px;
      }

      svg:last-child {
        align-self: baseline;
        margin-left: auto;
      }

      .toggle-indicator {
        color: var(--components-select-filter-dropdownIndicator);
        float: right;
        margin-top: 5px;
      }
    }
  }

  .toggle-indicator:hover {
    color: var(--components-select-filter-dropdownIndicator-hover);
  }

  .input-group input {
    margin-left: 0;
  }

  .filter-panel {
    margin-bottom: 0;
  }

  .filter-label {
    color: var(--search-page-filters);
    font-size: 0.8rem;
    margin-left: 10px;

    .btn {
      border-radius: 5px;
    }
  }

  .form-control {
    background: var(--search-page-filters-form-control-background);
    border: 1px solid var(--search-page-filters-form-control-border);
    border-radius: 2px;
    box-shadow: 2px 2px 3px var(--search-page-filters-form-control-shadow);
    color: var(--search-page-filters-form-control) !important;
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 400;
    height: 32px;
    line-height: 1;
    /* margin-left: 10px;
    margin-right: 10px; */
    padding: 0.5em 0.75em;
    text-decoration: none;

    &::placeholder {
      color: var(--search-page-filters-form-control-placeholder);
      font-size: 0.9rem;
    }
  }

  .input-group button.btn {
    background-color: var(--search-page-filters-form-control-btn-background);
    border: 1px solid var(--search-page-filters-form-control-border);
    border-color: var(--search-page-filters-form-control-btn-border);
    border-left: none;
    color: var(--search-page-filters-form-control-btn);
    cursor: default;
    margin-right: 0;
    outline: none !important;
    padding-bottom: 0;
    padding-left: 6px;
    padding-top: 0;
    width: 30px;

    &:active,
    &:focus {
      box-shadow: none;
      outline: none !important;
    }
  }

  .input-group-append {
    display: none;
  }

  .section {
    .section-title {
      svg {
        margin-right: 5px;
      }
    }

    .section-content {
      display: flex;
      flex-direction: column;

      button {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .section-title {
        margin-top: 16px;
      }
    }
  }

  .react-checkbox-tree {
    margin-left: 0;
  }
}
