.current-filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;

  &:hover {
    font-size: 0.9rem;
    line-height: 1;
  }

  @media (max-width: 800px) {
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .current-filters-buttons {
    button {
      color: var(--search-page-results-card-header);
      margin-left: 0;
      padding-left: 0;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    span.badge.bg-primary {
      font-size: 0.7rem;
      padding: 0.25em 0.4em;
    }
  }

  .btn.clear-search {
    border-bottom: 3px solid transparent;
    color: var(--search-page-results-current-filters-btn-clear-search);
    font-size: 0.9rem;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn,
  .badge {
    font-size: 0.9rem;
    line-height: 1;
  }
}

.current-filters-values {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  span {
    &.ui-chip {
      align-items: center;
      background-color: var(--search-page-results-current-filters-chip-background) !important;
      border: 1px solid var(--search-page-results-current-filters-chip-border);
      border-radius: 2px !important;
      color: rgb(33 37 41);
      display: flex;
      font-size: 0.9rem;
      font-weight: normal;
      gap: 5px;
      min-width: 50px;
      position: relative;

      svg {
        cursor: pointer;
      }

      &.ui-chip-loading {
        width: 200px;
      }

      span.ui-chip-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
