div.filters-sidebar {
  padding: 0;
}

.filters-sidebar .btn-link {
  color: var(--search-page-filters-btn-link);
}

.filters-sidebar button.btn.btn-link.inline-link {
  font-size: 0.9rem;
}

.filters-sidebar .accordion .card {
  margin-bottom: 5px;
  margin-left: 0;
  margin-top: 0;
  padding: 0;

  /* min-width: 300px; */
}

.filters-sidebar .accordion > .card > .card-header {
  background: var(--search-page-filters-background);
  border-left: 4px solid var(--search-page-filters-border-left);
  color: var(--search-page-filters);
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0 !important;

  /*   border-top: 1px solid var(--search-page-filters-border-top);
  border-bottom: 1px solid var(--search-page-filters-border-bottom);
  border-right: 1px solid var(--search-page-filters-border-right); */
  padding: 0.5rem 0;
}

.filters-sidebar .accordion .card-header .container {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.filters-sidebar .accordion .card-header svg {
  align-self: center;
  font-size: 1rem;
  margin-right: 8px;
}

.filters-sidebar .accordion .card-header svg:last-child {
  align-self: baseline;
  margin-left: auto;
}

.filters-sidebar .accordion .card-header .toggle-indicator {
  color: var(--components-select-filter-dropdownIndicator);
  float: right;
  margin-top: 5px;
}

.filters-sidebar .toggle-indicator:hover {
  color: var(--components-select-filter-dropdownIndicator-hover);
}

.filters-sidebar .accordion .card {
  border: solid 1px var(--search-page-filters-border);
  box-shadow: 2px 4px 8px var(--search-page-filters-border-shadow);
}

.filters-sidebar .accordion .card-body {
  padding: 1rem;
}

.filters-sidebar .input-group input {
  margin-left: 0;
}

.filter-panel {
  margin-bottom: 0;
}

.filter-label {
  color: var(--search-page-filters);
  font-size: 0.8rem;
  margin-left: 10px;
}

.filter-label .btn {
  border-radius: 5px;
}

.filters-sidebar .form-control {
  background: var(--search-page-filters-form-control-background);
  border: 1px solid var(--search-page-filters-form-control-border);
  border-radius: 2px;
  box-shadow: 2px 2px 3px var(--search-page-filters-form-control-shadow);
  color: var(--search-page-filters-form-control-placeholder) !important;
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 400;
  height: 32px;
  line-height: 1;
  /* margin-right: 10px;
  padding: 0.5em 0.75em; */
  text-decoration: none;
}

.filters-sidebar input.form-control::placeholder {
  color: var(--search-page-filters-form-control-placeholder) !important;
}

.filters-sidebar .input-group button.btn {
  background-color: var(--search-page-filters-form-control-btn-background);
  border: 1px solid var(--search-page-filters-form-control-border);
  border-color: var(--search-page-filters-form-control-btn-border);
  border-left: none;
  color: var(--search-page-filters-form-control-btn);

  /* box-shadow: 2px 2px 3px var(--search-page-filters-form-control-btn-shadow); */
  cursor: default;
  margin-right: 0;
  outline: none !important;
  padding-bottom: 0;
  padding-left: 6px;
  padding-top: 0;
  width: 30px;
}

.filters-sidebar .input-group button.btn:active,
.filters-sidebar .input-group button.btn:focus {
  box-shadow: none;
  outline: none !important;
}

.filters-sidebar .input-group-append {
  display: none;
}

.filters-sidebar .rmsc .dropdown-heading-value {
  font-size: 1rem;
}
