@use "../edit-page.scss";

.editor-collection-page.edit-page {
  .fileContainer {
    box-shadow: none;
    display: block;
    margin: 0;
    padding: 0;
  }

  .chooseFileButton {
    background-color: var(--components-button-load-more-background);
    border-radius: 3px;
    color: var(--components-button-load-more);
  }

  .chooseFileButton:hover {
    background-color: var(--components-button-load-more-hover-background);
    color: var(--components-button-load-more-hover);
  }

  .carousel p.legend {
    width: 80%;
  }

  label[for="imageData"] {
    align-items: center;
    display: flex;

    button {
      font-size: 0.9rem;
      margin-left: auto;
      padding: 0;
    }
  }

  .app-page-navigation {
    .collapse-container {
      position: sticky;
      top: 70px;
    }

    ul.collection-acts {
      li {
        list-style-type: decimal;
        margin-left: 15px;

        a {
          vertical-align: bottom;
        }
      }

      &.large {
        li {
          margin-left: 20px;
        }
      }
    }
  }

  .ql-editor,
  .ql-container {
    height: calc(100vh - 170px);

    p {
      text-indent: 0;
    }

    .summary-question {
      position: relative;

      button {
        color: rgb(108 117 125) !important;
        display: none;
        position: absolute;
        right: 10px;
        top: 5px;
      }

      &:hover {
        button {
          display: inline;
        }
      }
    }

    summary {
      list-style: none;
      text-align: justify;

      &:focus-visible {
        outline: none;
      }
    }

    details {
      p {
        color: rgb(108 117 125) !important;
        font-style: italic;
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: justify;

        --bs-text-opacity: 1;
      }

      span.meta-label {
        font-size: 0.9rem;
        font-weight: 600;
      }

      span.meta-value {
        font-weight: normal;
      }
    }

    p.preset-thesis {
      background: var(--summary-view-page-card-question-background);
      border-left: 2px solid var(--summary-view-page-card-question-border);
      font-size: 0.9rem;
      margin-bottom: 0;
      margin-top: 10px;
      padding: 1rem;
      text-align: justify;

      & + p.preset-thesis {
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
      }

      & + p:not(p.preset-thesis) {
        margin-top: 1rem;
      }
    }

    p.preset-comments {
      background-color: rgba(255 165 0 / 10%);
      border-left: 2px solid rgb(255 165 0);
      margin-bottom: 0;
      margin-top: 10px;
      padding: 1rem;

      & + p.preset-comments {
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
      }

      & + p:not(p.preset-comments) {
        margin-top: 1rem;
      }
    }

    p.preset-notes {
      border-top: 1px solid darkgray;
      color: darkgray;
      font-size: 0.9rem;
      font-style: italic;
      margin-bottom: 0;

      & + p.preset-notes {
        border-top: none;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
      }

      & + p:not(p.preset-notes) {
        margin-top: 1rem;
      }
    }
  }

  #editor-collection {
    h2,
    h3 {
      margin-bottom: 1rem;
    }

    h2,
    h3,
    h4,
    h5 {
      border-bottom: 1px dashed gray;
    }

    counter-reset: h2counter actscounter thesiscounter notescounter;

    h2 {
      counter-reset: h3counter;
    }

    h3 {
      counter-reset: h4counter;
    }

    h4 {
      counter-reset: h5counter;
    }

    h2::before {
      content: counter(h2counter) ". ";
      counter-increment: h2counter;
    }

    h3::before {
      content: counter(h2counter) "." counter(h3counter) ". ";
      counter-increment: h3counter;
    }

    h4::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ". ";
      counter-increment: h4counter;
    }

    h5::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "."
        counter(h5counter) ". ";
      counter-increment: h5counter;
    }

    .summary-title,
    .acts-title {
      counter-increment: actscounter;

      div.d-flex::before {
        content: counter(actscounter) ". ";
      }
    }

    *:not(.preset-thesis) + .preset-thesis {
      counter-increment: thesiscounter;

      &::before {
        content: "Теза № " counter(thesiscounter) ":";
        font-weight: 600;
        margin-right: 5px;
      }
    }

    .preset-notes {
      counter-increment: notescounter;

      &::before {
        content: counter(notescounter) ".";
        margin-right: 5px;
      }
    }
  }
}
