.stats-page {
  .filters-sidebar {
    .accordion-button:not(.collapsed) {
      background-color: inherit;
      box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12.5%);
      color: inherit;
    }

    .accordion-item > h2 > button {
      font-weight: 600;
      padding: 0.5rem 1rem;

      &::after {
        background-image: none;
      }

      &:focus {
        border: none;
        border-color: transparent;
        box-shadow: none;
        outline: none;
        outline-style: none;
      }
    }

    .accordion-body {
      padding: 0;
    }
  }

  .app-page-main-content {
    background: transparent;
    border: none;
  }

  td {
    span {
      display: none;
    }
  }

  .stats-champion {
    font-size: 1.2rem;
    font-weight: 600;

    span {
      color: rgb(237 175 51);
      display: inline-flex;
      font-size: x-small;
      padding: 0;
      position: absolute;
      vertical-align: super;
    }
  }

  .stats-loading-spinner {
    border-bottom-color: gray;
    border-left-color: gray;
    border-top-color: gray;
    border-width: 0.5rem;
    filter: opacity(0.5);
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 3rem;
  }

  .stats-container {
    position: relative;
  }

  .recharts-surface[role="application"] {
    margin-top: 25px;
  }

  .container .stats-table {
    margin-top: 40px;
  }

  .stats-table {
    th,
    td {
      padding: 5px;
      text-align: center;

      a {
        padding: 0.5rem;
      }
    }

    th {
      vertical-align: middle;
    }

    tfoot {
      font-weight: 600;
    }
  }

  .capitalize-first::first-letter,
  h4::first-letter {
    text-transform: uppercase;
  }

  h4 {
    text-align: right;
  }
}
