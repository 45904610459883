div.timeline {
  margin: auto;
  text-align: center;
}

div.timeline > span.timeline-now-title {
  border-bottom: 2px solid black;
  font-size: 1.5rem;
  padding-bottom: 10px;
}

div.timeline > span.timeline-now {
  font-size: 1.2rem;
  padding-bottom: 10px;
  padding-top: 0px;
  color: var(--doc-page-header);
}

div.timeline div.spacer {
  height: 40px;
}

div.timeline h5.no-data-message {
  text-align: center;
}

div.vertical-timeline-custom-line {
  margin-top: 10px;
  width: 90% !important;
}

/* @media only screen and (min-width: 1170px) {
  div.vertical-timeline-custom-line {
    width: 70% !important;
  }
} */

div.vertical-timeline-custom-line div.vertical-timeline-line-indicators {
  bottom: 5px;
  float: right;
  position: absolute;
  right: 5px;
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: var(--home-page-timeline-line-background);
  margin-left: -1px !important;
  width: 2px !important;
}

div.vertical-timeline-element-content {
  background-color: var(--home-page-timeline-background);
  border: 1px solid var(--home-page-timeline-background-border);
  box-shadow: none !important;
  padding: 0.5rem !important;
}

div.vertical-timeline-element-content:hover,
.vertical-timeline-element-content-arrow:hover {
  box-shadow: 1px 1px 2px var(--home-page-timeline-background-box-shadow) !important;
}

div.vertical-timeline-element {
  margin-bottom: 2rem !important;
  margin-top: 1rem !important;
}

.vertical-timeline-element-content-arrow {
  border-right-color: var(--home-page-timeline-background-border);
}

.vertical-timeline-element-icon {
  border: none !important;
  box-shadow: 0 0 0 10px var(--home-page-timeline-vertical-icon);
  height: 20px !important;
  margin-left: 5px !important;
  top: 10px !important;
  width: 20px !important;
}

@media only screen and (min-width: 1170px) {
  /* 1200 е breakpoint-а на компонента да е с две колони */
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    margin-left: -10px !important;
    margin-top: 12px;
  }
}

.vertical-timeline-element-content {
  cursor: pointer;
}

.vertical-timeline .impact-factor {
  background-color: var(--home-page-timeline-element-icon-background);
  border-radius: 50%;
  bottom: 5px;
  color: var(--home-page-timeline-element-icon);
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 5px;
  text-align: center;
  width: 30px;
}

.vertical-timeline .label {
  font-weight: 700;
}

div.vertical-timeline {
  position: relative;
}

div.vertical-timeline-more::after {
  background-image: linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(255 255 255 / 100%) 90%);
  bottom: 0;
  content: "";
  height: 10em;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.vertical-timeline-custom-line.date-clickable .vertical-timeline-element-date:hover {
  text-decoration: underline;
}

.vertical-timeline-custom-line:not(.date-clickable) .vertical-timeline-element-date {
  cursor: default;
}

.vertical-timeline-element-title.visited {
  color: var(--components-button-important-background);
}

.vertical-timeline-custom-line a.deco-none {
  color: inherit;
  text-decoration: inherit;
}

a.deco-none:link {
  color: inherit;
  text-decoration: inherit;
}

a.deco-none:hover {
  color: inherit;
  text-decoration: inherit;
}
