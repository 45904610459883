.related-acts button.remove-link {
  display: block;
  padding: 0;
  margin: auto;
  visibility: hidden;
}

.related-acts tr:hover button.remove-link {
  visibility: visible;
}
