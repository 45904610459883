.opinion-tabs.nav {
  overflow-x: auto;

  .nav-item {
    flex-shrink: 0;
  }
}

.sample-search-cards {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .search-card {
    background-color: var(--search-page-landing-page-card-background);
    border: 1px solid var(--search-page-landing-page-card-border);
    border-radius: 8px;
    color: var(--search-page-landing-page-card);
    cursor: pointer;
    display: flex;
    font-size: 0.9rem;
    gap: 1rem;
    padding: 15px;
    position: relative;
    transition: box-shadow 0.3s, transform 0.3s;

    .card-thumbnail {
      align-self: start;
      height: 25px;
      justify-self: start;
      margin: 0;
      opacity: 0.8;
      transition: opacity 0.3s ease;
      width: 25px;
    }

    .card-title {
      font-size: 0.9rem;
      line-height: 1.3;
      padding-top: 5px;
      text-align: left;
      white-space: normal;
    }

    &:hover {
      background-color: var(--search-page-landing-page-card-background-hover);
      border-color: var(--search-page-landing-page-card-border-hover);
      box-shadow: 0 0 11px var(--search-page-landing-page-card-shadow);
      transform: translateY(0);

      .card-thumbnail {
        opacity: 1;
      }
    }

    .preview {
      -webkit-box-orient: vertical;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      overflow: hidden;
    }
  }
}
