@use "../edit-page.scss";

.news-article-page.edit-page {
  .fileContainer {
    box-shadow: none;
    padding: 0;
    margin: 0;
    display: block;
  }

  .chooseFileButton {
    background-color: var(--components-button-load-more-background);
    color: var(--components-button-load-more);
    border-radius: 3px;
  }

  .chooseFileButton:hover {
    background-color: var(--components-button-load-more-hover-background);
    color: var(--components-button-load-more-hover);
  }

  .carousel p.legend {
    width: 80%;
  }

  label[for="imageData"] {
    display: flex;
    align-items: center;

    button {
      font-size: 0.9rem;
      padding: 0;
      margin-left: auto;
    }
  }
}
