.table-responsive {
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 576px) {
  .table-responsive {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.results-table-container {
  .load-more {
    margin-left: auto;
    margin-right: auto;
  }
}

.results-table {
  color: var(--search-page-results-table);

  td {
    border-top: none;

    & > div {
      padding-bottom: 1px;
      padding-top: 1px;
    }

    &.main-content {
      border-bottom: 1px solid var(--components-horizontal-scroll-menu-border);
    }
  }

  &.table > :not(:first-child) {
    border-top: none;
  }

  div.checkbox {
    align-items: center;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    & > .form-check {
      padding-left: 0;
    }

    svg {
      padding-top: 4px;
    }

    label {
      height: 8px;
    }
  }

  &.table-striped tbody tr:nth-of-type(odd),
  &.table-striped tbody tr:nth-of-type(even) {
    background-color: transparent;
  }

  a {
    color: var(--search-page-results-table-title);
    font-weight: 600;
  }

  a.questions-mode {
    font-weight: 500;
  }

  a.visited {
    color: var(--components-button-important-background);
  }

  .btn-link {
    padding: 0;
  }

  .row-content {
    display: flex;
    line-height: 20px;
  }

  thead {
    th.checkbox {
      width: 0.1%;
    }

    th.controls-bar {
      align-items: center;
      border: none;
      display: flex;
      font-size: 0.9rem;
      font-weight: normal;
      gap: 10px;

      .actions-dropdown {
        align-self: center;
        flex: 1;
        margin-right: auto;

        button {
          background: transparent;
          border: 1px solid var(--search-page-results-table-btn-border);
          border-radius: 2px;
          height: 30px;

          &:hover,
          &:focus {
            background: transparent;
          }
        }

        .dropdown-item {
          align-items: center;
          display: flex;
          gap: 8px;
          line-height: 1.2rem;
          text-decoration: none;

          svg {
            margin-top: 1px;
          }
        }

        @media (max-width: 500px) {
          .no-selection {
            display: none;
          }
        }
      }
    }

    .sort-control {
      align-items: center;
      display: flex;
      margin-left: auto;

      @media (max-width: 600px) {
        .sort-control-label {
          display: none;
        }
      }

      & > span {
        display: inline-block;
        line-height: 37px;
        margin-right: 5px;
        vertical-align: middle;
        white-space: pre;
      }

      & > .responsive-select > .rmsc {
        //min-width: 110px;
        width: 100%;

        .dropdown-container {
          background-color: transparent !important;
          border: none;

          .dropdown-heading {
            padding: 0;

            .dropdown-heading-value span {
              color: var(--view-page-btn-link);
              font-weight: 500;
            }

            .arrow {
              color: var(--view-page-btn-link);
              padding-right: 0;
            }
          }

          .dropdown-content {
            overflow: visible;
            padding-top: 0;
            right: 0;
            width: auto;

            div.item-renderer {
              white-space: nowrap;
            }
          }
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      & > button {
        background-color: var(--search-page-results-table-btn-background) !important;
        border: none;
        color: var(--view-page-btn-link);
        padding: 4px 5px;

        &:hover {
          color: var(--view-page-btn-link);
        }

        &:focus {
          box-shadow: none !important;
          color: var(--view-page-btn-link);
          outline: none !important;
        }
      }
    }
  }

  tbody {
    font-size: 0.9rem;
  }

  .lv-label {
    font-weight: 600;
  }

  .row-main-info {
    max-width: 95%;
  }

  .row-indicators {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: auto;
  }

  .row-highlights {
    padding-top: 10px;
  }

  .mentions {
    cursor: pointer;
    float: right;
  }

  @media (max-width: 800px) {
    .row-content {
      flex-direction: column;

      .row-main-info {
        max-width: 100%;
      }

      .row-indicators {
        align-items: flex-start;
        flex-direction: row;
        margin-left: 0;

        .indicator-icons.empty {
          display: none;
        }

        .mentions {
          float: initial;
        }
      }
    }

    .table-responsive {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

#user-stats-tooltip .tooltip-inner {
  max-width: 600px;

  td,
  th {
    padding: 5px;
    text-align: center;
  }
}
