.search-input.input-group {
  border-bottom: 1px solid var(--search-page-results-border);

  .form-control {
    background: var(--search-page-results-background);
    border: none;

    &:focus {
      z-index: 1;
    }
  }

  .input-group-text {
    background-color: transparent;
    border: none;
    font-size: 0.8rem;
    font-style: italic;
    max-width: 40%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  button {
    background-color: var(--search-page-results-btn-background);
    color: var(--search-page-results-btn);
    padding-bottom: 0;
    padding-top: 0;

    &:active,
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    &.active {
      background-color: var(--search-page-results-btn);
      color: var(--search-page-results-btn-background);
    }
  }

  .float-end .btn {
    border: none;
  }

  .float-end .btn:hover {
    border: none;
    color: var(--search-page-results-table-float-right-btn-hover);
  }

  .float-start svg {
    color: var(--search-page-results-btn);
    margin-right: 10px;
    opacity: 0.5;
  }
}

#search-input-modal {
  .modal-body {
    padding-left: 0;
    padding-right: 0;
  }

  .rbt-menu.dropdown-menu {
    border: none !important;
    max-height: calc(100vh - 50px) !important;
    position: fixed !important;
    top: 18px !important;
    width: 100vw !important;

    .dropdown-item {
      padding: 0.5rem;
      white-space: normal;
    }
  }

  input,
  button svg {
    border: none;
    color: black;
    font-size: 1.5rem !important;
  }

  .dropdown-menu {
    & > span,
    .dropdown-header {
      padding-bottom: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

.rbt-menu.dropdown-menu {
  max-height: 500px !important;

  .dropdown-item {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
