@use "../../components/TopicSelect/topic-select.css";

.summary-page.view-page {
  .info-card {
    .inactive-case-act-link {
      font-size: 0.9rem;
    }

    .info-card-case-related-act {
      li:not(:hover) > button {
        display: none;
      }

      a:hover + button {
        display: inline-block;
      }
    }
  }

  .opinion-author {
    display: none;
  }

  mark {
    background-color: transparent;
    padding: 0;
  }

  &.searching {
    mark {
      background-color: rgb(253 252 159);

      &.current {
        background: orange;
      }
    }

    .tab-pane {
      display: block !important;
    }

    .opinion-tabs {
      display: none;
    }

    .opinion-author {
      display: block;
      font-weight: bold;
      margin-bottom: 10px;
      text-decoration: underline;
    }
  }

  .question {
    .accordion .card {
      margin-left: 0;
    }

    .tabs-2 .nav-item {
      max-width: 50%;
    }

    .tabs-3 .nav-item {
      max-width: 33%;
    }

    .tabs-4 .nav-item {
      max-width: 25%;
    }

    .opinion-tabs.nav {
      flex-wrap: nowrap;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 0;

      .nav-item {
        min-width: 0;

        &:last-of-type {
          flex-shrink: 0;
        }

        &:only-child {
          flex-shrink: 1;
        }

        .nav-link {
          border-radius: 2px;
          color: var(--summary-view-page-nav-link);
          cursor: pointer;
          padding: 0.25rem 1rem;
          padding-top: 0;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .nav-link.active {
          background-color: transparent;
          border-bottom: 3px solid var(--summary-view-page-nav-link);
          color: var(--summary-view-page-nav-link-active);
          cursor: default;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .tooltip {
    inset: auto auto 0 0 !important;
    max-height: 40vh;
    overflow-y: auto;
    padding: 0 !important;
    position: fixed !important;
    transform: initial !important;
    width: 100% !important;

    .tooltip-arrow {
      display: none !important;
    }

    .tooltip-inner {
      max-width: 100% !important;
    }
  }
}

.provision-tooltip div.tooltip-inner {
  max-height: 50vh;
  max-width: 400px;
  overflow-y: auto;
  scrollbar-color: rgb(153 153 153) rgb(51 51 51);
  scrollbar-width: 12px;
  text-align: left;

  & > div {
    margin-top: 1rem;
  }

  @media (min-width: 600px) {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(153 153 153);
      border: 3px solid rgb(51 51 51);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgb(51 51 51);
    }
  }
}

.provision-tooltip-xl div.tooltip-inner {
  max-width: 600px;
}
