.app-page {
  padding-top: 70px;

  @media (min-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .app-page-main-content {
    background-color: var(--search-page-landing-page-container-background);
    border: 1px solid var(--search-page-landing-page-container-border);
    box-shadow: 2px 4px 8px var(--search-page-landing-page-container-border-shadow);
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
  }

  .app-page-navigation {
    margin-right: 20px;
    max-width: 400px;
    padding: 0;
  }

  .copyright {
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

#copyright-tooltip .tooltip-inner {
  max-width: 500px !important;
}

.app-page-navigation,
#navigation-drawer {
  font-size: 0.9rem;

  ul {
    list-style: none;
    padding: 0;
  }
}

.mobile-view .app-page {
  padding-bottom: 40px;
  padding-top: 0;
}

#navigation-drawer.mobile-view {
  max-width: none;
  width: 100vw;
}

#navigation-drawer {
  display: flex !important;
  flex-direction: column-reverse;
  max-width: 40vw;

  .offcanvas-body {
    padding: 0;

    @media (min-width: 576px) {
      max-width: 400px;
      width: 80vw;
    }

    .DayPicker {
      flex-direction: row;
      height: 100%;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .DayPicker-Months {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
    }

    .DayPicker-Day {
      border-radius: 10%;
    }

    .accordion {
      .card {
        border: 0;
        margin-left: 0;

        .card-header {
          border-left: 0;
          border-top: 1px solid rgb(0 0 0 / 12.5%);
        }
      }
    }

    .accordion + .accordion {
      margin-top: 0;
    }
  }

  .close-drawer-icon {
    display: none;
  }

  .filters-sidebar {
    background-color: rgb(255 255 255);
    height: 100%;
  }

  .btn.load-more {
    font-size: 0.75rem;
  }

  .form-control {
    margin-left: 0;
  }
}

.drawer-handle {
  align-items: center;
  background: rgb(255 255 255);
  border-radius: 40px;
  bottom: 100px;
  box-shadow: 3px 0 8px rgb(0 0 0 / 30%);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  left: 8px;
  line-height: 40px;
  position: fixed;
  text-align: center;
  width: 40px;
  z-index: var(--z-index-drawer-handle);
}

.mobile-view {
  #page-map {
    margin-left: -28px;
  }

  .app-page-main-content {
    margin-right: 0 !important;
  }

  .document-page-main-content-body {
    padding-right: 2rem !important;
  }
}
