@use "../DocumentPage/generic-popover.scss";

#documents-popover,
#documents-menu-modal {
  .popover-body {
    padding: 0;
  }

  .list-group-item a {
    font-weight: 400 !important;
  }

  .accordion-button {
    .copy-button {
      display: flex;
      margin-left: auto;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    &.collapsed {
      .copy-button {
        display: none;
      }
    }
  }
}

.documents-menu-toggle::after,
.stat-menu .dropdown-toggle::after {
  display: none;
}
