.article-details {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-top: 5px;
}

.article-details > button.btn.btn-light.important {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.article-details button {
  padding: 0.3rem 0.7rem;
  display: inline-block;
  color: var(--home-page-article-details);
  border: 1px solid var(--home-page-article-details-background);
  border-radius: 2px;
  background-color: var(--home-page-article-details-background);
  font-weight: 400;
  transition: all 0.5s;
  margin-right: 5px;
  box-shadow: 2px 2px 3px var(--home-page-article-details-box-shadow) !important;
  line-height: 1;
  font-size: 0.7rem;
}

.article-details button:hover {
  background-color: var(--home-page-article-details-background);
  border: 1px solid var(--home-page-article-details);
  color: var(--home-page-article-details);
}

div.article-details .item-details {
  font-size: 0.7rem;
  margin-left: 10px;
}

.ib-carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: var(--home-page-background);
  opacity: 0.7;
}

.carousel {
  height: 200px;
}

.carousel img {
  opacity: 0.6;
  width: 100%;
  position: absolute;
}

.carousel div.legend {
  width: 60% !important;
  top: 25px !important;
}

@media only screen and (max-width: 1170px) {
  .carousel div.legend {
    width: 97% !important;
    left: 3% !important;
  }
}

.carousel div.legend .article-title {
  max-height: 3.6em;
  line-height: 1.1em;
}

.carousel div.legend .article-details {
  font-size: calc(6px + 1vw);
  min-height: 20px;
}

.carousel div.legend .article-subtitle {
  font-size: 1rem;
  min-height: 20px;
  padding-top: 0.4rem;
}

.carousel .splide__pagination li button {
  margin-left: 8px;
  margin-right: 8px;
  background-color: var(--app-navbar-link-active);
  opacity: 0.3;
}

.carousel .splide__pagination li button.is-active {
  background-color: var(--app-navbar-link-active);
  opacity: 1;
  transform: scale(1);
}

img.highlighted-article-image {
  object-fit: cover;
  height: 200px;
}

div.highlighted-articles-loader {
  height: 200px;
}
