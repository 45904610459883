.rte-expandable .quill {
  transition: height 0.5s ease;
  transition: height 0.5s ease;
  transition: height 0.5s ease;
  transition: height 0.5s ease;
  transition: height 0.5s ease;
}

.ql-editor p {
  line-height: 20px;
  margin-bottom: 1rem;
  text-indent: 40px;
}

.ql-editor > :first-child {
  margin-top: 0.9em;
}

.ql-container.ql-snow {
  border-color: var(--components-select-filter-control-border);
  border-radius: 0 0 4px 4px;
  color: var(--bs-body-color);
  font-size: 16px;
}

.ql-toolbar {
  background-color: var(--components-select-filter-control-background);
  border: 1px solid var(--ck-color-toolbar-border);
}

.ql-toolbar.ql-snow {
  border-color: var(--components-select-filter-control-border);
  border-radius: 4px 4px 0 0;
}

.ql-picker.ql-header {
  .ql-picker-label::before {
    content: "Нормален" !important;
  }

  .ql-picker-label[data-value="1"]::before {
    content: "Заглавие 1" !important;
  }

  .ql-picker-label[data-value="2"]::before {
    content: "Заглавие 2" !important;
  }

  .ql-picker-label[data-value="3"]::before {
    content: "Заглавие 3" !important;
  }

  .ql-picker-label[data-value="4"]::before {
    content: "Заглавие 4" !important;
  }

  .ql-picker-label[data-value="5"]::before {
    content: "Заглавие 5" !important;
  }
}

.ql-picker.ql-header {
  .ql-picker-item[data-value=""]::before {
    content: "Нормален" !important;
  }

  .ql-picker-item[data-value="2"]::before {
    content: "Заглавие 2" !important;
  }

  .ql-picker-item[data-value="3"]::before {
    content: "Заглавие 3" !important;
  }

  .ql-picker-item[data-value="4"]::before {
    content: "Заглавие 4" !important;
  }

  .ql-picker-item[data-value="5"]::before {
    content: "Заглавие 5" !important;
  }
}

.ql-picker.ql-preset {
  .ql-picker-label {
    align-items: flex-start;
    display: flex;

    svg {
      position: relative !important;
    }
  }

  .ql-picker-label::before {
    content: "Текст" !important;
  }

  .ql-picker-label[data-value="thesis"]::before {
    content: "Теза" !important;
  }

  .ql-picker-label[data-value="notes"]::before {
    content: "Бел. под линия" !important;
  }

  .ql-picker-label[data-value="comments"]::before {
    content: "Работна бележка" !important;
  }
}

.ql-picker.ql-preset {
  .ql-picker-item[data-value=""]::before {
    content: "Текст" !important;
  }

  .ql-picker-item[data-value="thesis"]::before {
    content: "Теза" !important;
  }

  .ql-picker-item[data-value="notes"]::before {
    content: "Бел. под линия" !important;
  }

  .ql-picker-item[data-value="comments"]::before {
    content: "Работна бележка" !important;
  }
}

.ql-picker.ql-bp {
  .ql-picker-label {
    align-items: flex-start;
    display: flex;

    svg {
      position: relative !important;
    }
  }

  .ql-picker-label::before {
    content: "Текст" !important;
  }

  .ql-picker-label[data-value="topic"]::before {
    content: "Тема" !important;
  }

  .ql-picker-label[data-value="title"]::before {
    content: "Връзка акт/резюме" !important;
  }

  .ql-picker-label[data-value="question"]::before {
    content: "Въпрос" !important;
  }

  .ql-picker-label[data-value="answer"]::before {
    content: "Отговор" !important;
  }
}

.ql-picker.ql-bp {
  .ql-picker-item[data-value=""]::before {
    content: "Текст" !important;
  }

  .ql-picker-item[data-value="question"]::before {
    content: "Въпрос" !important;
  }

  .ql-picker-item[data-value="answer"]::before {
    content: "Отговор" !important;
  }
}

.ql-tooltip::before {
  content: "Адрес на връзката:" !important;
}

.ql-tooltip[data-mode="link"]::before {
  content: "Адрес на връзката" !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Запази" !important;
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: "Редакция" !important;
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: "Премахни" !important;
}

.ql-snow.ql-toolbar {
  display: flex;
}

.ql-snow.ql-toolbar .ql-collapse {
  margin-left: auto;
}

.rte-headless {
  .ql-toolbar {
    display: none;
  }

  .ql-editor {
    padding: 4px 12px;
  }

  .ql-container {
    background-color: var(--components-select-filter-control-background);
    border-radius: 4px;
  }

  p {
    margin: 0 !important;
    padding: 0 !important;
    text-indent: 0;
  }
}

.rte-single-line {
  height: 30px;
}
