button.btn.btn-light.load-more,
button.dropdown-toggle.load-more {
  background-color: var(--components-button-load-more-background);
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 3px var(--components-button-load-more-shadow) !important;
  color: var(--components-button-load-more);
  display: flex;
  font-weight: 400;
  margin: 10px auto;
  padding: 0.3rem 0.7rem;
  padding: 5px 15px;
  transition: all 0.5s;
  white-space: nowrap;
}

button.dropdown-toggle.load-more {
  display: block;
}

.load-more.dropdown.btn-group {
  display: flex;
  flex-direction: row;
  width: 160px;
}

.modal-footer button.btn.btn-light.load-more,
.modal-footer button.btn.btn-light.secondary,
.modal-footer button.btn.btn-light.primary,
.modal-footer button.btn.btn-light.important {
  margin: 0.25rem;
}

button.btn.btn-light.load-more:hover:enabled {
  background-color: var(--components-button-load-more-hover-background);
  border: none;
  color: var(--components-button-load-more-hover);
}

button.btn.btn-light.important:hover:enabled {
  background-color: var(--components-button-important-hover-background);
  border: none;
  color: var(--components-button-important-hover);
}

button.btn.btn-light.secondary:hover:enabled {
  background-color: var(--components-button-secondary-hover-background);
  border: none;
  color: var(--components-button-secondary-hover);
}

button.btn.btn-link.inline-link,
button.btn.btn-link.inline-link:focus {
  border: none;
  box-shadow: none;
  font-size: 0.94rem;
  outline: none !important;
  padding: 0;
  text-align: left;
  -webkit-touch-callout: text;
  user-select: text;
  user-select: text;
  user-select: text;
  user-select: text;
  user-select: text;
  vertical-align: text-bottom;
}

button.btn.btn-light.important {
  background-color: var(--components-button-important-background);
  border: none;
  border-radius: 2px;
  box-shadow: 2px 2px 3px var(--components-button-important-shadow) !important;
  color: var(--components-button-important);
  display: flex;
  font-weight: 400;
  margin: 10px auto;
  padding: 0.3rem 0.7rem;
  padding: 5px 15px;
  transition: all 0.5s;
}

button.btn.btn-light.secondary {
  background-color: var(--components-button-secondary-background);
  border: none;
  border-radius: 2px;
  box-shadow: 2px 2px 3px var(--components-button-secondary-shadow) !important;
  color: var(--components-button-secondary);
  display: block;
  font-weight: 400;
  margin: 10px auto;
  padding: 0.3rem 0.7rem;
  padding: 5px 15px;
  transition: all 0.5s;
}
