@use "../view-page.scss";

.editor-collection-page.view-page {
  .published-by {
    display: flex;
    font-style: italic;
    margin-top: 3px;

    .like-button button {
      margin-left: 0;
    }

    span.date {
      margin-left: auto;
    }
  }

  mark {
    background-color: transparent;
    padding: 0;
  }

  &.searching {
    mark {
      background-color: rgb(253 252 159);

      &.current {
        background: orange;
      }
    }
  }

  .act-text {
    counter-reset: h2counter actscounter thesiscounter notescounter;
    margin-bottom: 50vh;
    margin-top: 40px;

    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 2rem;

      @media (min-width: 1200px) {
        margin-left: 40px;
      }
    }

    h2 {
      counter-reset: h3counter;
    }

    h3 {
      counter-reset: h4counter;
    }

    h4 {
      counter-reset: h5counter;
    }

    h2::before {
      content: counter(h2counter) ". ";
      counter-increment: h2counter;
    }

    h3::before {
      content: counter(h2counter) "." counter(h3counter) ". ";
      counter-increment: h3counter;
    }

    h4::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ". ";
      counter-increment: h4counter;
    }

    h5::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "."
        counter(h5counter) ". ";
      counter-increment: h5counter;
    }

    a {
      color: var(--view-page-btn-link);
    }

    .preset-thesis {
      counter-increment: thesiscounter;

      & > summary::before {
        content: "Теза № " counter(thesiscounter) ":";
        font-weight: 600;
        margin-right: 5px;
      }
    }

    .preset-notes {
      counter-increment: notescounter;

      &::before {
        content: "[" counter(notescounter) "]";
        font-style: normal;
        margin-right: 5px;
      }
    }

    .summary-title,
    .act-title {
      @media (min-width: 1200px) {
        margin-left: 40px;
      }

      a {
        font-size: 1rem;
      }

      div.d-flex::before {
        color: var(--view-page-btn-link);
        content: counter(actscounter) ".";
        margin-right: 3px;
      }

      counter-increment: actscounter;
    }

    .summary-question {
      @media (min-width: 1200px) {
        margin-left: 40px;
      }

      button {
        display: none;
      }
    }

    summary {
      font-weight: 600;
      list-style: none;
      text-align: justify;

      &:focus-visible {
        outline: none;
      }
    }

    details {
      p {
        font-style: italic;
        margin-bottom: 10px !important;
        margin-top: 10px;
        text-align: justify;

        --bs-text-opacity: 1;
      }

      &:not([open])::after {
        bottom: 0.3rem;
        content: url("data:image/svg+xml,%3Csvg stroke='rgb(169, 169, 169)' fill='rgb(169, 169, 169)' stroke-width='0' viewBox='0 0 320 512' class='toggle-indicator' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'%3E%3C/path%3E%3C/svg%3E");
        height: 1rem;
        position: absolute;
        right: 1rem;
      }

      &[open]::after {
        bottom: 0.3rem;
        content: url("data:image/svg+xml,%3Csvg stroke='rgb(169, 169, 169)' fill='rgb(169, 169, 169)' stroke-width='0' viewBox='0 0 320 512' class='toggle-indicator' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z'%3E%3C/path%3E%3C/svg%3E");
        height: 1rem;
        position: absolute;
        right: 1rem;
      }
    }

    details.preset-thesis {
      border-left: 3px solid var(--summary-view-page-card-question-border);
      font-size: 0.9rem;
      margin-left: 40px;
      margin-top: 10px;
      padding: 1rem;
      position: relative;
      text-align: justify;
      text-indent: 40px !important;

      summary {
        margin-bottom: 1rem;
      }

      p {
        font-style: normal;
        margin-bottom: 0 !important;
        margin-top: 5px !important;
      }
    }

    p.preset-notes {
      border-top: 1px solid darkgray;
      color: darkgray;
      font-size: 0.9rem;
      font-style: italic;
      margin-left: 40px;
      text-indent: 0 !important;

      a {
        font-size: 0.9rem;
      }

      & + p.preset-notes {
        border-top: none;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    span.meta-label {
      font-size: 0.9rem;
      font-weight: 600;
    }

    span.meta-value {
      font-size: 0.9rem;
      font-weight: normal;
    }
  }

  .app-page-navigation {
    .collapse-container {
      height: calc(100vh - 40px);
      overflow: auto;
      padding-bottom: 40px;
      position: sticky;
      scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
      scrollbar-width: thin;
      top: 70px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-bar-color);
        border-radius: 5px;
      }

      ul.collection-acts {
        li {
          color: var(--view-page-btn-link);
          list-style-type: decimal;
          margin-left: 15px;

          a {
            vertical-align: bottom;
          }
        }

        &.large {
          li {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
