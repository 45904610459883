@use "../view-page.scss";

.news-article-page.view-page {
  .published-by {
    display: flex;
    font-style: italic;
    margin-top: 3px;

    .like-button button {
      margin-left: 0;
    }

    span.date {
      margin-left: auto;
    }
  }

  .act-text {
    margin-top: 40px;
  }
}
