@use "../view-page.scss";

.drawer-content {
  .document-page-main-content .act-text {
    margin-right: 0;
  }
}

#quick-act-body .r6o-annotation {
  cursor: default;
}
