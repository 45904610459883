.react-checkbox-tree {
  margin-left: 10px;
}

.react-checkbox-tree button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: var(--components-checkbox-tree);
}

.react-checkbox-tree button:focus {
  outline: none;
}

.react-checkbox-tree input[type="checkbox"] + span + span + span {
  padding: 0.2rem 0.4rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin-left: 0.1rem;
}
.react-checkbox-tree input[type="checkbox"]:checked + span + span + span,
.react-checkbox-tree input[type="checkbox"] + span + span + span:hover {
  background: var(--components-checkbox-tree-input-background);
  color: white;
  cursor: pointer;
  border-radius: 2px;
}

.react-checkbox-tree .rct-disabled > span input[type="checkbox"] + span + span + span,
.react-checkbox-tree .rct-disabled > span input[type="checkbox"] + span + span + span:hover {
  background: none !important;
  color: gray;
  cursor: default;
}

.react-checkbox-tree input[type="checkbox"] {
  display: none;
}

.react-checkbox-tree ol {
  list-style-type: none;
}

.react-checkbox-tree > ol {
  padding-left: 0px;
}

.react-checkbox-tree li > ol {
  padding-left: 15px;
}

.react-checkbox-tree li {
  line-height: 0.8rem;
}

.rct-icon-leaf {
  margin-right: 16px;
}

.topic-filter .collapse {
  overflow-y: auto;
}

.rct-title {
  display: inline-block;
}

.rct-text {
  display: flex;
  align-items: flex-start;
}

.rct-text button {
  padding-top: 7px;
}
