.document-page {
  @media (min-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .app-page-main-content {
    padding-bottom: 20px;
    padding-top: 5px;

    table {
      overflow-x: initial;

      tr td,
      thead,
      tfoot,
      tbody {
        border: none;
        padding: 0;
      }

      td {
        max-width: 100px;
      }
    }
  }

  @media (max-width: 576px) {
    .app-page-main-content > div {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .document-page-main-content-header-container {
    z-index: 1 !important;
  }

  .document-page-main-content-header {
    align-items: center;
    background-color: var(--background-color);
    border-bottom: 2px solid var(--doc-page-header-border);
    display: flex;
    height: 41px;
    margin-bottom: 20px;
    z-index: 2;

    & > div,
    & > button {
      margin-left: auto;
    }

    .h6 {
      align-items: center;
      color: var(--doc-page-header);
      margin-bottom: 0;
      overflow: hidden;
      place-self: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .document-status-badge {
      height: fit-content;
    }

    .document-access-indicator-badge {
      color: var(--doc-page-header);
      padding-left: 0;

      svg {
        font-size: 0.9rem;
        margin-left: 0;
        vertical-align: middle;
      }
    }

    .actions-dropdown svg {
      margin-right: 5px;
    }

    .actions-dropdown .dropdown-item > a.dropdown-item {
      padding: 0;
    }

    .actions-dropdown a.dropdown-item {
      padding-left: 0.75rem;
    }
  }
}

.document-page-main-content-header-actions {
  display: flex;
  justify-content: flex-end;

  button.btn.btn-light {
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 0;
    margin-top: 5px;
  }

  button {
    margin-left: 5px;

    svg {
      color: rgb(0 81 128);
      font-size: 1.2rem;
      margin-bottom: 3px;
    }
  }
}

#actions-panel-popover,
#actions-panel-popover-quickview {
  position: fixed !important;

  &[data-popper-reference-hidden="true"] {
    display: block !important;
    margin-top: -50px;
  }

  &.bs-popover-bottom > .popover-arrow,
  &.bs-popover-bottom > .popover-arrow::after {
    border-width: 0 0.5rem 0.5rem;
    display: none;
    top: 1px;
  }
}

#actions-panel-popover {
  z-index: var(--z-tooltip-actions-popover);
}

#actions-panel-popover-quickview {
  margin-right: 45px !important;
  margin-top: 10px !important;
  transform: none !important;
  z-index: var(--z-tooltip-actions-popover-quickview);
}
