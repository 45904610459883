@use "../view-page.scss";

.bulletin-page.view-page {
  .DayPicker {
    flex-direction: row;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .DayPicker-Months {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .DayPicker-Day {
    border-radius: 10%;
  }

  .DayPicker-Day--restricted {
    color: gray;
    position: relative;

    svg {
      font-size: 1.4rem;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      opacity: 0.1;
      position: absolute;
      right: 0;
      text-align: center;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: var(--app-navbar-link-hover);
  }

  .toc {
    h2 {
      margin-bottom: 2rem;
      margin-top: 2rem;
      text-indent: 40px;
    }
  }

  .stats-loading-spinner {
    border-bottom-color: gray;
    border-left-color: gray;
    border-top-color: gray;
    border-width: 0.5rem;
    filter: opacity(0.5);
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 3rem;
  }

  .stats-container {
    position: relative;

    svg:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  #b-disclaimer {
    color: rgb(108 117 125);
    margin-top: 3rem;
  }

  .recharts-tooltip-cursor {
    display: none;
  }

  .act-text {
    counter-reset: h2counter actscounter thesiscounter notescounter;
    margin-bottom: 50vh;
    margin-top: 40px;

    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 2rem;
    }

    h2 {
      counter-reset: h3counter;
      margin-top: 2rem;
    }

    h3 {
      counter-reset: h4counter;
    }

    h4 {
      counter-reset: h5counter;
    }

    h2::before {
      content: counter(h2counter) ". ";
      counter-increment: h2counter;
    }

    h3::before {
      content: counter(h2counter) "." counter(h3counter) ". ";
      counter-increment: h3counter;
    }

    h4::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ". ";
      counter-increment: h4counter;
    }

    h5::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "."
        counter(h5counter) ". ";
      counter-increment: h5counter;
    }

    a {
      border-bottom: 1px dashed lightgray;
      color: var(--view-page-btn-link);
    }

    .ql-indent-1 {
      padding-left: 40px;
    }

    .bp-topic {
      font-size: 0.9rem;
    }

    .bp-answer {
      text-indent: 40px;

      &:has(+ .show-more-btn)::after {
        content: "...";
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .bp-question {
      margin-top: 1rem;
    }

    .bp-title {
      counter-increment: actscounter;
      margin-top: 2rem;

      a {
        font-size: 1rem;
      }

      &::before {
        color: var(--view-page-btn-link);
        content: counter(actscounter) ".";
        margin-right: 3px;
      }
    }
  }

  .app-page-navigation {
    .collapse-container {
      height: calc(100vh - 40px);
      overflow: auto;
      padding-bottom: 40px;
      position: sticky;
      scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
      scrollbar-width: thin;
      top: 70px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-bar-color);
        border-radius: 5px;
      }
    }
  }
}
