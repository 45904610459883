.scrollTop {
  bottom: 30px;
  color: var(--component-scroll-top-arrow);
  cursor: pointer;
  flex: none;
  height: 40px;
  margin-left: calc(85% - 30px);
  max-width: 40px;
  padding: 0;
  position: absolute;
  z-index: var(--z-index-scroll-top-arrow);
}

.scrollTop-window {
  position: fixed;
}

.scrollTop svg {
  opacity: 0.5;
}

.router:not(.mobile-view) .scrollTop svg:hover {
  opacity: 1;
}

.mobile-view .scrollTop{
  bottom: 70px;
  right: 25px;
}
