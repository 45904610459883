@use "../edit-page.scss";

.bulletin-page.edit-page {
  .app-page-navigation {
    .collapse-container {
      position: sticky;
      top: 70px;
    }
  }

  .ql-editor,
  .ql-container {
    height: calc(100vh - 170px);

    p {
      text-indent: 0;
    }

    .bp-question {
      position: relative;

      button {
        color: rgb(108 117 125) !important;
        display: none;
        position: absolute;
        right: 10px;
        top: 5px;
      }

      &:hover {
        button {
          display: inline;
        }
      }
    }

    .bp-question {
      margin-top: 1rem;
    }

    p {
      text-align: justify;
      text-indent: 40px;
    }

    .bp-topic {
      font-size: 0.9rem;
    }

    .bp-title {
      margin-top: 2rem;

      a {
        font-size: 1rem;
      }
    }
  }

  #editor-bulletin {
    h2,
    h3 {
      margin-bottom: 1rem;
    }

    h2,
    h3,
    h4,
    h5 {
      border-bottom: 1px dashed gray;
    }

    counter-reset: h2counter actscounter thesiscounter notescounter;

    h2 {
      counter-reset: h3counter;
    }

    h3 {
      counter-reset: h4counter;
    }

    h4 {
      counter-reset: h5counter;
    }

    h2::before {
      content: counter(h2counter) ". ";
      counter-increment: h2counter;
    }

    h3::before {
      content: counter(h2counter) "." counter(h3counter) ". ";
      counter-increment: h3counter;
    }

    h4::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ". ";
      counter-increment: h4counter;
    }

    h5::before {
      content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "."
        counter(h5counter) ". ";
      counter-increment: h5counter;
    }

    .bp-title {
      counter-increment: actscounter;

      &::before {
        content: counter(actscounter) ". ";
      }
    }
  }
}
