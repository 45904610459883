.topics-table {
  button {
    &.remove-link {
      display: none;
      margin: 0;
      padding: 0;
    }
  }

  tr {
    &:hover {
      button {
        &.remove-link {
          display: contents;
        }
      }
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.rst__rowWrapper {
  height: 40px !important;
  padding: 0 !important;
}
