.article-details {
  align-items: center;
  display: flex;
  font-size: 1rem;
  min-height: 50px;
  padding-top: 5px;
}

.article-details > button.btn.btn-light.important {
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.article-details button {
  background-color: var(--home-page-article-details-background);
  border: 1px solid var(--home-page-article-details-background);
  border-radius: 2px;
  box-shadow: 2px 2px 3px var(--home-page-article-details-box-shadow) !important;
  color: var(--home-page-article-details);
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1;
  margin-right: 5px;
  padding: 0.3rem 0.7rem;
  transition: all 0.5s;
}

.article-details button:hover {
  background-color: var(--home-page-article-details-background);
  border: 1px solid var(--home-page-article-details);
  color: var(--home-page-article-details);
}

div.article-details .item-details {
  font-size: 0.7rem;
  margin-left: 10px;
}

.ib-carousel-overlay {
  background: var(--home-page-background);
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.carousel {
  height: 200px;
}

.carousel img {
  opacity: 0.3;
  position: absolute;
  width: 100%;
}

.carousel div.legend {
  background: none;
  bottom: unset;
  color: var(--home-page-carousel);
  left: 11%;
  line-height: 20px;
  margin-bottom: 1rem;
  margin-top: 0;
  opacity: unset;
  position: absolute;
  text-align: left;
  top: 30px;
  transition: unset;
  width: 60%;
}

.mobile-view .carousel div.legend {
  width: 80%;
}

.carousel .splide__pagination li button {
  background-color: var(--app-navbar-link-active);
  margin-left: 8px;
  margin-right: 8px;
  opacity: 0.3;
}

.carousel .splide__pagination li button.is-active {
  background-color: var(--app-navbar-link-active);
  opacity: 1;
  transform: scale(1);
}

img.highlighted-article-image {
  height: 200px;
  object-fit: cover;
}

div.highlighted-articles-loader {
  height: 200px;
}
