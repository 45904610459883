.indicator-icons {
  display: inline;
  padding-right: 5px;
}

span.ai {
  height: 0.9rem;
  scale: 0.7;
  width: 0.9rem;
}

.indicator-icons svg {
  color: var(--search-page-results-table-btn);
  cursor: pointer;
  height: 1.1rem;
  width: 1.1rem;
}

#euleg-tooltip .tooltip-inner,
#anno-tooltip .tooltip-inner {
  max-width: max-content;
}
